import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subject, zip} from 'rxjs';
import {Activity} from '../models/activity';
import {map, mergeMap, take, takeUntil} from 'rxjs/operators';
import {AthleteActivity} from '../models/athlete-activity';
import {AuthService} from '@auth0/auth0-angular';
import {environment} from '../../../environments/environment';
import {ActivitiesGroupedByTemplate} from '../models/activities-grouped-by-template';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
    private _baseUrl = `${environment.api.url}/activities`;
    constructor(private _http: HttpClient,
                private auth: AuthService) { }


    getWorkoutsByActivityTemplateIdAndDateRange(activitiesTemplatesIds: string [],
                                                start: any,
                                                end: any)
    {
      let url;
      activitiesTemplatesIds = activitiesTemplatesIds || ['all'];
      if (activitiesTemplatesIds?.length) {
        url = `${this._baseUrl}/filter/grouped-by-template?activitiesTemplatesIds=${activitiesTemplatesIds}&start=${start}&end=${end}`;
      } else {
        url = `${this._baseUrl}/filter/grouped-by-template?start=${start}&end=${end}`;
      }
      return this._http.get<ActivitiesGroupedByTemplate []>(url).pipe(
        map(activities => {
          const result = [];
          for(const activity in activities) {
            if(activity) {
              const item = new ActivitiesGroupedByTemplate({name: activity, activities: activities[activity]});
              result.push(item);
            }
          }
          return result;
        }
      ));
    }


  getAthleteUpcomingActivities(): Observable<AthleteActivity []> {
    return this.auth.user$.pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(authzId => this._http.get<AthleteActivity []>(`${this._baseUrl}/upcoming/athletes/${authzId}`)),
      map(acs => acs.map(ac => new AthleteActivity(ac)
        ))
    );
  }


  getAthleteCancelUpcomingActivities(): Observable<AthleteActivity []> {
    return this.auth.user$.pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(authzId => this._http.get<AthleteActivity []>(`${this._baseUrl}/upcoming-cancel-late/athletes/${authzId}`)),
      map(acs => acs.map(ac => new AthleteActivity(ac)
      ))
    );
  }


}
