import {Component, NgZone, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {mergeMap, take} from 'rxjs/operators';
import {Browser} from '@capacitor/browser';
import {callbackUri} from '../auth.config';
import {Router} from '@angular/router';
import {Platform} from '@ionic/angular';
import {NotificationService} from './notification/services/notification.service';
import {LoaderService} from './loader.service';
import {StateService} from './state.service';
import {firstValueFrom} from 'rxjs';
import { ScreenOrientation } from '@capacitor/screen-orientation';

import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent {
  isReady = false;
  constructor(public auth: AuthService,
              private ngZone: NgZone,
              private platform: Platform,
              private notificationService: NotificationService,
              private router: Router,
              public stateService: StateService,
              public loaderService: LoaderService) {
        this.platform.ready().then(async () => {
          App.addListener('appUrlOpen', this.onAppUrlOpen);
          await ScreenOrientation.lock({ orientation: 'portrait' });
        });
  }

  ngOnInit() {
    this.onTenantChange();
  }

  private onAppUrlOpen =  async (event: URLOpenListenerEvent) => {
    await this.ngZone.run(async () => {
      if (event.url?.startsWith(callbackUri)) {
        if (
          event.url.includes('state=') &&
          (event.url.includes('error=') || event.url.includes('code='))
        ) {
          this.auth
          .handleRedirectCallback(event.url)
          .pipe(mergeMap(() => Browser.close()))
          .subscribe({
            next: async () => {
              await this.router.navigate(['']);
            },
            error: async (err: any) => {
              console.log('app component', err);
              if(this.platform.is('android')) {
                await this.router.navigate(['']);
              }

            }
          });
        } else {
          await Browser.close();
        }
      }
    });
  };


  private  onTenantChange() {
    this.stateService.refreshData$.subscribe(async (refresh) => {
      if (refresh) {
        this.isReady = false;
        await this.stateService.refreshPlans();

        await this.stateService.refreshNotifications();

        await this.stateService.refreshAthleteOrgs$();

        await this.stateService.refreshPrTemplatesGroupedByFamilies();

        await this.stateService.refreshFluxes();

        await this.stateService.refreshCoachNotes();

        await this.stateService.refreshAthleteUpComingActivities();

        await this.stateService.refreshAthleteSubscriptions();

        await this.stateService.refreshAthletePaymentMethodsStatus();

        await this.stateService.refreshNotifications();

        await this.stateService.refreshCoachNotes();

        await this.stateService.refreshShopCategories();

        await this.stateService.refreshAthleteInyourboxCredit();

        await this.stateService.refreshAthleteOrders();

        await this.stateService.refreshCurrentOrganizationPlan((await firstValueFrom(this.stateService.currentTenant$))._id);

        this.isReady = true;
      }
    });
  }


}

