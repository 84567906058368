import { Injectable } from '@angular/core';
import {map, mergeMap, take} from 'rxjs/operators';
import {of} from 'rxjs';
import {AuthService} from '@auth0/auth0-angular';
import {HttpClient} from '@angular/common/http';
import {InyourboxGeneratorOrder} from '../models/inyourbox-generator-order';
import {environment} from '../../../environments/environment';
import {WorkoutDto} from '../models/workout.dto';
import {Audit} from '../models/audit';

@Injectable({
  providedIn: 'root'
})
export class InyourboxGeneratorOrderService {
  private baseUrl = `${environment.api.url}/inyourbox-generator/orders`;
  constructor(private auth: AuthService,
              private http: HttpClient) { }

  getByAuthzId() {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(authzId => {
        if(authzId) {
          return this.http.get<InyourboxGeneratorOrder>(`${this.baseUrl}/athletes/${authzId}` );
        }
        return of(null);
      })
    );
  }

  order() {
    return this.http.post(`${this.baseUrl}`, {});
  }

  generate(orderId: string, workout: WorkoutDto) {
    return this.http.post(`${this.baseUrl}/generate`, workout);
  }

  getAudit() {
    return this.http.get<Audit>(`${this.baseUrl}/audit`);
  }

  getStripePortal() {
    return this.http.get<{ url: string }>(`${this.baseUrl}/stripe-portal`);
  }
}
