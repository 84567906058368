import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@auth0/auth0-angular';
import {PrTemplate, Template} from '../models/pr-template';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PrTemplateService {

  private baseUrl = `${environment.api.url}/pr-templates`;
  constructor(private http: HttpClient,
              private auth: AuthService) { }

  getPrTemplates() {
    return this.http.get<PrTemplate[]>(`${this.baseUrl}`).pipe(
      map((prTemplates) => prTemplates?.map(prt => new PrTemplate(prt))));
  }


  getPrTemplatesGroupedByFamilies() {
    return this.http.get<Template []>(`${this.baseUrl}/grouped-by-families`);
  }
}
