import {PrFamily} from './pr-groups';
import {Family} from './family';

export class PrTemplate {
  _id: string;
  name: string;
  unity: string;
  group: string;
  family: string;
  order: number;
  familyId: string;
  description: string;

  constructor(data?: any) {
    data = data || {};
    this._id = data._id;
    this.name = data.name;
    this.unity = data.unity;
    this.group = data.group;
    this.family = data.family;
    this.order = data.order;
    this.familyId = data.familyId;
    this.description = data.description;
  }
}

export class Template {
  family: Family;
  templates: PrTemplate [];
}
