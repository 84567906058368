import {Injectable} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {map, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Notification} from '../models/notification';
import {BehaviorSubject, EMPTY, Subject} from 'rxjs';
import {environment} from '../../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class NotificationService {
  public badge: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private baseUrl =`${environment.api.url}/notifications`;

  constructor(private auth: AuthService,
              private http: HttpClient) {
  }

  getNotifications() {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(authzId => this.http.get<Notification []>(`${this.baseUrl}/athletes/${authzId}`)),
      map(notifications => notifications.map(notification => new Notification(notification))),
      tap((ns: Notification[]) => this.badge.next(ns?.filter(n => !n.opened).length || 0))
    );
  }

  open(id: string) {
    return this.http.post(`${this.baseUrl}/${id}/open`, {});
  }

  openAll() {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(authzId => this.http.post<Notification []>(`${this.baseUrl}/athletes/${authzId}/open-all`, {})),
    );
  }

}
