import { Injectable } from '@angular/core';
import {map, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Category} from '../models/category';
import {Product} from '../models/product';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private baseUrl = `${environment.api.url}/shop/products`;
  constructor(private http: HttpClient) { }

  getAllGroupedByCategories(): Observable<{ category: Category; products: Product [] } []> {
    return this.http.get<{ category: Category; products: Product [] } []>(`${this.baseUrl}/grouped-by-categories`);
  }

}
