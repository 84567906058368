import { Injectable } from '@angular/core';
import {BehaviorSubject, lastValueFrom, Subject} from 'rxjs';
import {Athlete} from './athlete/models/athlete';
import {AthleteOrg} from './athlete/models/athlete-org';
import {Trophy} from './athlete/models/athlete-trophy';
import {Plan} from './workout-plan/models/plan';
import {TrophyService} from './athlete/services/trophy.service';
import {AthleteService} from './athlete/services/athlete.service';
import {PlanService} from './workout-plan/services/plan.service';
import {Notification} from './athlete/components/notification/models/notification';
import {NotificationService} from './athlete/components/notification/services/notification.service';
import {Organization, OrganizationSettings} from './athlete/models/organization';
import {OrganizationService} from './athlete/services/organization.service';
import {Template} from './athlete-pr/models/pr-template';
import {PrTemplateService} from './athlete-pr/services/pr-template.service';
import {FluxService} from './home/services/flux.service';
import {Flux} from './home/models/flux';
import {NotesService} from './chat/services/notes.service';
import {CoachNotes} from './chat/models/coach-notes';
import {AthleteActivity} from './scheduler/models/athlete-activity';
import {ActivityService} from './scheduler/services/activity.service';
import {Subscription} from './athlete/models/subscription';
import {SubscriptionService} from './athlete/services/subscription.service';
import {OrgPlan} from './plan';
import {Category} from './shop/models/category';
import {ProductService} from './shop/services/product.service';
import {Product} from './shop/models/product';
import {AthleteInyourboxCredit} from './athlete/models/athlete-inyourbox-credit';
import {Order} from './shop/models/order';
import {OrderService} from './shop/services/order.service';
import {Activity} from './scheduler/models/activity';
import {takeUntil} from 'rxjs/operators';
import {InyourboxGeneratorOrder} from './inyourbox-generator/models/inyourbox-generator-order';
import {
  InyourboxGeneratorOrderService
} from './inyourbox-generator/services/inyourbox-generator-order.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  public currentTenant$: BehaviorSubject<AthleteOrg> = new BehaviorSubject<AthleteOrg>(null);
  public refreshData$: Subject<boolean> = new Subject<boolean>();
  public athlete$: BehaviorSubject<Athlete> = new BehaviorSubject<Athlete>(null);
  public plans$: BehaviorSubject<Plan [] > = new BehaviorSubject([]);
  public notifications$: BehaviorSubject<Notification []> = new BehaviorSubject([]);
  public athleteOrgs$: BehaviorSubject<AthleteOrg []> = new BehaviorSubject([]);
  public organizations$: BehaviorSubject<Organization []> = new BehaviorSubject<Organization[]>([]);
  public currentOrganizationPlan$: BehaviorSubject<OrgPlan> = new BehaviorSubject<OrgPlan>(null);
  public currentOrganizationSettings$: BehaviorSubject<OrganizationSettings> = new BehaviorSubject<OrganizationSettings>(null);
  public prTemplatesGroupedByFamilies$: BehaviorSubject<Template []> = new BehaviorSubject<Template[]>([]);
  public fluxes$: BehaviorSubject<Flux []> = new BehaviorSubject<Flux[]>([]);
  public categories$: BehaviorSubject<{ category: Category; products: Product [] } []> =
  new BehaviorSubject<{ category: Category; products: Product [] }[]>([]);
  public coachNotes$: BehaviorSubject<CoachNotes> = new BehaviorSubject<CoachNotes>(null);
  public athleteUpComingActivities$: BehaviorSubject<AthleteActivity []> = new BehaviorSubject<AthleteActivity []>(null);
  public athleteSubscriptions$: BehaviorSubject<Subscription []> = new BehaviorSubject<Subscription []>(null);
  public athletePaymentMethodsStatus$:  BehaviorSubject<{status: boolean}> = new BehaviorSubject<{status: boolean}>(null);
  public ready$:  BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public athleteInyourboxCredit$: BehaviorSubject<AthleteInyourboxCredit> = new BehaviorSubject<AthleteInyourboxCredit>(null);
  public athleteOrders$: BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>([]);
  public activities$: BehaviorSubject<Activity []> = new BehaviorSubject<Activity[]>([]);
  public inyourboxGeneratorOder$: BehaviorSubject<InyourboxGeneratorOrder> = new BehaviorSubject<InyourboxGeneratorOrder>(null);
  constructor(
              private athleteService: AthleteService,
              private inyourboxGeneratorOrderService: InyourboxGeneratorOrderService,
              private planService: PlanService,
              private notificationService: NotificationService,
              private organizationService: OrganizationService,
              private prTemplateService: PrTemplateService,
              private fluxService: FluxService,
              private noteService: NotesService,
              private activityService: ActivityService,
              private subscriptionService: SubscriptionService,
              private orderService: OrderService,
              private categoryService: ProductService) { }


  async refreshAthlete() {
    this.athlete$.next(await lastValueFrom(this.athleteService.getAthleteById()));
  }

  async refreshInyourboxGeneratorOder() {
    this.inyourboxGeneratorOder$.next(await lastValueFrom(this.inyourboxGeneratorOrderService.getByAuthzId()));
  }


  async refreshPlans() {
    this.plans$.next(await lastValueFrom(this.planService.getPlans()));
  }

  async refreshNotifications() {
    this.notifications$.next(await lastValueFrom(this.notificationService.getNotifications()));
  }

  async refreshAthleteOrgs$() {
    this.athleteOrgs$.next(await lastValueFrom(this.athleteService.getAthleteOrgs()));
  }

  async refreshOrganizations() {
    this.organizations$.next(await lastValueFrom(this.organizationService.getOrganizations()));
  }

  async refreshPrTemplatesGroupedByFamilies() {
    this.prTemplatesGroupedByFamilies$.next(await lastValueFrom(this.prTemplateService.getPrTemplatesGroupedByFamilies()));
  }

  async refreshFluxes() {
    this.fluxes$.next(await lastValueFrom(this.fluxService.getAll()));
  }

  async refreshCoachNotes() {
    this.coachNotes$.next(await lastValueFrom(this.noteService.getCoachNotes()));
  }

  async refreshAthleteUpComingActivities() {
    this.athleteUpComingActivities$.next(await lastValueFrom(this.activityService.getAthleteUpcomingActivities()));
  }

  async refreshAthleteSubscriptions() {
    this.athleteSubscriptions$.next(await lastValueFrom(this.subscriptionService.getAthleteSubscriptions()));
  }

  async refreshAthletePaymentMethodsStatus() {
    this.athletePaymentMethodsStatus$.next(await lastValueFrom(this.athleteService.getAthletePaymentMethodsStatus()));
  }

  async refreshCurrentOrganizationPlan(id: string) {
    const org = await lastValueFrom(this.organizationService.getOrganizationById(id));
    this.currentOrganizationPlan$.next(new OrgPlan(org.plan));
    this.currentOrganizationSettings$.next(org.settings);

  }

  async refreshShopCategories() {
    const categories = await lastValueFrom(this.categoryService.getAllGroupedByCategories());
    this.categories$.next(categories);
  }

  async refreshAthleteInyourboxCredit() {
    this.athleteInyourboxCredit$.next(await lastValueFrom(this.athleteService.getAthleteInyourboxCredit()));
  }

  async refreshAthleteOrders() {
    this.athleteOrders$.next(await lastValueFrom(this.orderService.findByAthleteId()));
  }




}
