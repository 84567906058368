import {Activity} from './activity';

export class ActivitiesGroupedByTemplate {
  name: string;
  activities: Activity [];
  constructor(data: any) {
    this.name = data?.name;
    this.activities = data?.activities?.map(activity => new Activity(activity));
  }
}
