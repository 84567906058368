import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Note} from '../models/note';
import {environment} from '../../../environments/environment';
import {AuthService} from '@auth0/auth0-angular';
import {map, mergeMap, take} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {CoachNotes} from '../models/coach-notes';
import {LightAthlete} from '../../home/models/light-athlete';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  private baseUrl =`${environment.api.url}/notes`;

  constructor(private http: HttpClient, private auth: AuthService) { }

  getCoachNotes(): Observable<CoachNotes> {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(authzId => this.http.get<Note []>(`${this.baseUrl}/coach/${authzId}`)),
      mergeMap(notes => {
        const coachNotes = new CoachNotes();
        const distinctAthletes = notes.
        map(note => note.athlete)
        .filter(this.distinct);
        const athletes = distinctAthletes.map(athlete => ({
            athlete,
            notes: notes.filter(note => note?.athlete.authzId === athlete?.authzId),
            newMessages:
              notes?.filter(note => note?.athlete?.authzId === athlete?.authzId)?.filter(note => note?.newMessages)?.length > 0
          }));
        coachNotes.notes = notes;
        coachNotes.athletes = athletes;
        return of(coachNotes);
      })
    );

  }


  distinct(value: LightAthlete, index, array) {
    return array.findIndex(athlete => athlete.authzId === value.authzId) === index;
  }

}
