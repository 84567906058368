import {Like} from './like';
import {FluxComment} from './flux-comment';
import {LightAthlete} from './light-athlete';
import {Score} from '../../workout-plan/models/score';
import {AthletePr} from '../../athlete-pr/models/athlete-pr';

export class Flux {
  _id: string;
  image: string;
  date: Date;
  content: string;
  shortContent: string;
  displayAllContent: boolean;
  athlete: LightAthlete;
  comments: FluxComment[];
  likes: Like[];
  pin: boolean;

  constructor(data: any) {
    data = data || {};
    this._id = data._id;
    this.image = data.image;
    this.date = new Date(data.date);
    this.content = data.content;
    this.shortContent = this.content?.length > 50 ? this.content?.substring(0, 50).replace(/\n/g, '   ') + '     . . .' : null;
    this.athlete = data.athlete;
    this.comments = data?.comments?.map(c => new FluxComment(c));
    this.likes = data?.likes?.map(l => new Like(l));
    this.pin = data?.pin;
  }
}
