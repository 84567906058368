export class AthleteActivity {
  name: string;
  start: Date;
  end: Date;
  type: string;
  rank: number;
  activityId: string;
  bookingId: string;

  constructor(data?: any) {
    data = data || {};
    this.name = data.name;
    this.start = new Date(data.start);
    this.end = new Date(data.end);
    this.type = data.type;
    this.rank = data.rank;
    this.activityId = data.activityId;
    this.bookingId = data.bookingId;
  }
}
