import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Flux} from '../models/flux';
import {catchError, map, mergeMap, take, takeUntil} from 'rxjs/operators';
import {Observable, of, Subject} from 'rxjs';
import {AuthService} from '@auth0/auth0-angular';
import {Like} from '../models/like';
import {FluxComment} from '../models/flux-comment';
import {FluxTag} from '../models/flux-tag';
import {BlackListedFlux} from '../models/black-listed-flux';
import {BlackListedAthlete} from '../models/black-listed-athlete';

@Injectable({
  providedIn: 'root'
})
export class FluxService {
  private baseUrl = `${environment.api.url}/flux`;

  constructor(private http: HttpClient, private auth: AuthService) {
  }

  getAll(): Observable<Flux[]> {
    return this.http.get<Flux[]>(`${this.baseUrl}`).pipe(
      map(flux => flux.map(f => new Flux(f)))
    );
  }

  pin(fluxId: string) {
    return this.http.post<Flux[]>(`${this.baseUrl}/${fluxId}/pin`, {});
  }

  like(scoreId: string) {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(authzId => {
        if(authzId) {
          return this.http.post<Like>(`${this.baseUrl}/${scoreId}/like/${authzId}`, {});
        }
        return of(null);
      })
    );
  }

  deleteLike(likeId: string) {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(authzId => this.http.delete<Like>(`${this.baseUrl}/likes/${likeId}`))
    );
  }

  comment(fluxId: string, comment: any) {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(authzId => this.http.post<FluxComment>(`${this.baseUrl}/${fluxId}/comment/${authzId}`, comment))
    );
  }

  deleteComment(commentId: any) {
    return this.http.delete<FluxComment>(`${this.baseUrl}/comments/${commentId}`);
  }

  create(flux: Flux) {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(authzId => this.http.post<FluxComment>(`${this.baseUrl}/athletes/${authzId}`, flux))
    );

  }

  uploadImage(file: any, fluxId: string): Observable<Flux> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http
    .post<Flux>(`${this.baseUrl}/${fluxId}/image`, formData).pipe(
      catchError((err => {
        console.log('error', err);
        throw err;
      }))
    );
  }

  deleteFlux(fluxId: string) {
    return this.http.delete<FluxComment>(`${this.baseUrl}/${fluxId}`);
  }

  tag(fluxTag: FluxTag) {
    return this.http.post(`${this.baseUrl}/tag`, fluxTag);
  }

  blacklistFlux(blacklistedFlux: BlackListedFlux) {
    return this.http.post(`${this.baseUrl}/blacklist`, blacklistedFlux);
  }

  blacklistAthlete(blacklistedAthlete: BlackListedAthlete) {
    return this.http.post(`${this.baseUrl}/${blacklistedAthlete.blackListedAthleteId}/blacklist`, blacklistedAthlete);
  }



}
