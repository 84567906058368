import {AthleteActivity} from './athlete-activity';

export class Activity {
  _id: string;
  name: string;
  color: string;
  duration: number;
  primaryListLimit: number;
  secondaryListLimit: number;
  start: Date;
  end: Date;
  hasRecurrence: boolean;
  parentId: string;
  activityTemplateId: string;
  bookingsNumber: number;

  constructor(data: any) {
    data = data || {};
    this._id = data._id || null;
    this.name = data.name || null;
    this.color = data.color || null;
    this.duration = data.duration || null;
    this.primaryListLimit = data.primaryListLimit || null;
    this.secondaryListLimit = data.secondaryListLimit || null;
    this.start = new Date(data.start) || null;
    this.end = new Date(data.end) || null;
    this.hasRecurrence = data.hasRecurrence || null;
    this.parentId = data.parentId || null;
    this.activityTemplateId = data.activityTemplateId || null;
    this.bookingsNumber = data.bookingsNumber || 0;
  }

}
