export class Notification {
  _id: string;
  authzId: string;
  message: string;
  opened: boolean;
  destination: string[];
  data: any;
  date: Date;
  image: string;

  constructor(data: any) {
    data = data || {};
    this._id = data._id;
    this.authzId = data.authzId;
    this.message = data.message;
    this.opened = data.opened;
    this.destination = data.destination;
    this.data = data.data;
    this.date = data.date;
    this.image = data.image;
  }
}
