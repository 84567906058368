export const environment = {
  production: true,
  interceptor: {
    excludedUrls: [
      '../../assets/i18n/fr.json'
    ],
    withoutTenantId: [
      'organizations',
      'https://inyourbox-prod.eu.auth0.com/oauth/token'
    ]
  },
  api: {
    url: 'https://inyourbox-api-prod.internal.nephos.app'
  },
  authz: {
    domain: 'inyourbox-prod.eu.auth0.com',
    clientId: 'QG6ZoihxJntxvW0J2jgyDqg5PrzM8WsY',
    audience: 'https://api.inyourbox.app'
  },
  inyourbox: {
    default: '64ed0248645b4ac5f6ca106b'
  }
};
