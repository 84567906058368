import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpClient
} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {finalize, switchMap, take, takeUntil} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {AuthService} from '@auth0/auth0-angular';
import {LoaderService} from './loader.service';
import {AthleteOrg} from './athlete/models/athlete-org';
import {StateService} from './state.service';
export interface CurrentTenant {
  athleteOrg: AthleteOrg;
  first: boolean;
}
@Injectable()
export class InyourboxInterceptorService implements HttpInterceptor {
  static readonly  currentTenant: BehaviorSubject<CurrentTenant> = new BehaviorSubject<CurrentTenant>(null);
  private readonly withoutTenantId: string [];

  constructor(
    private loaderService: LoaderService,
    private auth: AuthService,
    private stateService: StateService
  ) {
    this.withoutTenantId = environment.interceptor.withoutTenantId;
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({ headers: request.headers
      .set('ngrok-skip-browser-warning', '69420')
    });
    this.loaderService.isLoading.next(true);
    const unsubscribeAll = new Subject();
    return this.stateService.currentTenant$.pipe(
      take(1),
      switchMap(tenant => {
        if(!tenant?._id) {
          return of(null);
        }
        return of(tenant?._id);
      }),
      switchMap(tenantId => this.addTenantIdToHeader(request, tenantId)),
      switchMap((req: HttpRequest<any>) => next.handle(req)),
      finalize(() => {
        this.loaderService.isLoading.next(false);
      })
    );
  }


  addTenantIdToHeader(request: HttpRequest<any>, id: string | null): Observable<HttpRequest<any>> {
    let requestToHandle;
    if(request.headers.has('custom-tenant')) {
      requestToHandle = request.clone({ headers: request.headers
        .set('x-tenant-id', request.headers.get('custom-tenant'))
      });
      return of(requestToHandle);
    }
    if(this.shouldContainTenantId(request.url)) {
      if(id === null || id === undefined || id === 'null' || id === 'undefined') {
        id = environment.inyourbox.default;
      }
      requestToHandle = request.clone({ headers: request.headers
        .set('x-tenant-id', `${id}`)
      });
    }else {
      requestToHandle = request;
    }
    return  of(requestToHandle);
  }


  private shouldContainTenantId(url: string): boolean {
    for(const uri of this.withoutTenantId) {
      if(url.indexOf(uri) > -1) {
        return false;
      }
    }
    return true;
  }
}
