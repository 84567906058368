export class OrgPlan {

  _id: string;
  name: string;
  marketplace: boolean;
  digitalSign: boolean;
  booking: boolean;

  constructor(data?: any) {
    this._id = data?._id;
    this.name = data?.name;
    this.marketplace = data?.marketplace;
    this.digitalSign = data?.digitalSign;
    this.booking = data?.booking;
  }

}
