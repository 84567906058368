import {Product} from './product';
import {Athlete} from '../../athlete/models/athlete';


export class Order {
  _id: string;
  items: {product: Product; qte: number}[];
  athlete: Athlete;
  date: Date;

  constructor(data?: any) {
    this._id = data?._id;
    this.athlete = data?.athlete;
    this.date = new Date(data?.date);
    this.items = data?.items;
  }
}
