import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, mergeMap, take, takeUntil} from 'rxjs/operators';
import {AuthService} from '@auth0/auth0-angular';
import {environment} from '../../../environments/environment';
import {EMPTY, lastValueFrom, Observable, Subject} from 'rxjs';
import {Athlete} from '../models/athlete';
import {AthleteInyourboxCredit} from '../models/athlete-inyourbox-credit';

@Injectable({
  providedIn: 'root'
})
export class AthleteService {
  private baseUrl = `${environment.api.url}/athletes`;

  constructor(private http: HttpClient,
                private auth: AuthService) { }

  getAthleteById() {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(authzId => this.http
        .get<Athlete []>
        (`${this.baseUrl}/${authzId}`)),
      map((athlete) => new Athlete(athlete)));

  }

  uploadAvatar(file: any, athleteId: string): Observable<Athlete> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http
    .post<Athlete>(`${this.baseUrl}/${athleteId}/avatar`, formData);
  }

  getStripePortal(): Observable<{ url: string }> {
    return this.http.get<{url: string}>(`${this.baseUrl}/stripe/portal`);
  }


  requestDelete() {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(authzId => this.http
      .post<any>
      (`${this.baseUrl}/${authzId}/delete-account-request`, {})));
  }

  getAthleteOrgs() {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(user => this.http
      .get<any>
      (`${environment.api.url}/organizations/athletes/${user}`)));
  }

  addOrg(orgId: string, code: string) {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(user => this.http
      .post<any>
      (`${environment.api.url}/athletes/register/${user}/add-org?code=${code}`, {},
        {headers: {'custom-tenant': orgId}})));
  }


  favourite(orgId: string) {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(user => this.http
      .post<any>
      (`${environment.api.url}/organizations/${orgId}/favourite/athletes/${user}`, {})));
  }

  getAthletePaymentMethodsStatus() {
    return this.http.get<{status: boolean}>(`${environment.api.url}/athletes/payment-methods-status`);
  }


  getAthleteInyourboxCredit() {
    return  this.auth.user$.pipe(
      take(1),
      map(user => user?.sub),
      mergeMap((athleteId) => this.http.get<AthleteInyourboxCredit>(`${this.baseUrl}/${athleteId}/inyourbox-credit`)));
  }




}
