import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {InYourBoxAuthGuard} from './services/auth.guard';

const routes: Routes = [
  {
    title: 'tabs',
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [InYourBoxAuthGuard]
  },
  {
    title: 'login',
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    title: 'register',
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterModule)
  },
  {
    title: 'timer',
    path: 'timer',
    loadChildren: () => import('./timer/timer.module').then( m => m.TimerModule)
  },
  {
    title: 'default',
    path: '',
    redirectTo: '/tabs/athlete',
    pathMatch: 'full'
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
