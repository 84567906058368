import {LightAthlete} from './light-athlete';

export class FluxComment {
  _id: string;
  image: string;
  content: string;
  athlete: LightAthlete;
  date: Date;

  constructor(data: any) {
    this._id = data._id;
    this.image = data.image;
    this.content = data.content;
    this.athlete = data.athlete;
    this.date = new Date(data.date);
  }
}
