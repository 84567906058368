import {Address} from './address';
import {SafeUrl} from '@angular/platform-browser';

export class Athlete {
  authzId: string;
  email: string;
  stripeId: string;
  password: string;
  firstname: string;
  lastname: string;
  birthday: Date;
  phone: string;
  avatar: string | SafeUrl;
  address: Address;
  notes: string;
  type: string;
  companyName: string;
  initials: string;
  status: string;
  constructor(data: any) {
    data = data || {};
    this.authzId = data.authzId || null;
    this.email = data.email || null;
    this.stripeId = data.stripeId || null;
    this.password = data.password || null;
    this.firstname = data.firstname || null;
    this.lastname = data.lastname || null;
    this.birthday = new Date(data.birthday) || null;
    this.phone = data.phone || null;
    this.avatar = data.avatar || null;
    this.address = data.address || null;
    this.notes = data.notes || null;
    this.type = data.type;
    this.companyName = data.companyName;
    this.initials = data.initials;
  }

}
