import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, mergeMap, Observable} from 'rxjs';
import {Order} from '../models/order';
import {environment} from '../../../environments/environment';
import {Product} from '../models/product';
import {AuthService} from '@auth0/auth0-angular';
import {take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private baseUrl = `${environment.api.url}/shop/orders`;
  constructor(private http: HttpClient, private auth: AuthService) { }

  order(data: { items: { productId: string; qte: number }[]; paymentMethod: string }, athleteId: string): Observable<{
    order: Order; errors: {items: {products: Product; qte: number} []; status: string; reason: string};
  }> {
    return this.http.post<{
      order: Order; errors: {items: {products: Product; qte: number} []; status: string; reason: string};}>
    (`${this.baseUrl}/athletes/${athleteId}`, data);
  }


  findByAthleteId() {
    return this.auth.user$.pipe(take(1),map(user=> user?.sub),
      mergeMap(athleteId => this.http.get<Order []>(`${this.baseUrl}/athletes/${athleteId}`)),
      map(orders => orders.map(order => new Order(order)))
    );
  }




}
