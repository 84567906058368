import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@auth0/auth0-angular';
import {map, mergeMap, take, takeUntil} from 'rxjs/operators';
import {Subscription} from '../models/subscription';
import {EMPTY, Observable, Subject} from 'rxjs';
import {AthleteAttendanceMonth} from '../models/athlete-attendance-month';

@Injectable({providedIn: 'root'})
export class SubscriptionService {

  private baseUrl = `${environment.api.url}/subscriptions`;
  constructor(private http: HttpClient,
              private auth: AuthService) { }

  getAthleteSubscriptions() {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(authzId => this.http.get<Subscription []>(`${this.baseUrl}/athlete/${authzId}`)),
      map((subscriptions: Subscription[]) => subscriptions.map(s => new Subscription(s)))
    );
  }

  sign(subscriptionId: string): Observable<any> {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(authzId => this.http.post<any>(`${this.baseUrl}/${subscriptionId}/sign?athleteId=${authzId}`, {}))
    );
  }

  confirm(subscriptionId: string, code: any): Observable<any> {
    return this.auth.user$
    .pipe(
      take(1),
      map(user => user?.sub),
      mergeMap(authzId => this.http.post<any>(`${this.baseUrl}/${subscriptionId}/confirm-signature?athleteId=${authzId}`, code))
    );
  }

  validateSubscription(subscriptionId: string): Observable<Subscription> {
    return this.http.post<Subscription>(`${this.baseUrl}/${subscriptionId}/validate`, {});
  }

  getSubscriptionBookingsNumber(subscriptionId: string) {
    return this.http.get<{ count: number }>(`${environment.api.url}/bookings/subscriptions/${subscriptionId}`);
  }


}
