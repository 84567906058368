import { isPlatform } from '@ionic/angular';
import config from '../capacitor.config';
import {environment} from './environments/environment';

export const domain = environment.authz.domain;
export const clientId = environment.authz.clientId;
const { appId } = config;

// Use `auth0Domain` in string interpolation below so that it doesn't
// get replaced by the quickstart auto-packager
const auth0Domain = domain;
const iosOrAndroid = isPlatform('ios') || isPlatform('android');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const redirect_uri = //iosOrAndroid
  //? `${appId}://${auth0Domain}/capacitor/${appId}/callback`
  //: 'http://localhost:8100';
     'https://athlete.inyourbox.app';

export const callbackUri = //iosOrAndroid
  //? `${appId}://${auth0Domain}/capacitor/${appId}/callback`
  //: 'http://localhost:8100';
     'https://athlete.inyourbox.app';

export const returnTo = //iosOrAndroid
  //? `${appId}://${auth0Domain}/capacitor/${appId}/callback`
  //: 'http://localhost:8100';
     'https://athlete.inyourbox.app';
