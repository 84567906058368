import {SubscriptionTemplate} from './subscription-template';
import {Payment} from './payment';


export class StripeProperties {
  stripeSubscriptionId: string;
  proration: boolean;
  prorationPriceId: string;
  status: string;
  constructor(data: any) {
    this.stripeSubscriptionId = data?.stripeSubscriptionId;
    this.proration = data?.proration;
    this.prorationPriceId = data?.prorationPriceId;
    this.status = data?.status;
  }
}


export class SubscriptionProperties {
  start: Date | null;
  end: Date | null;
  stripeProperties: StripeProperties;
  price: number;
  cancelStart: Date;
  cancelEnd: Date;
  isCanceled: boolean;
  quantity: number;
  permanent: boolean;
  constructor(data: any) {
    data = data || {};
    this.start = new Date(data?.start) ||  null;
    this.end = new Date(data?.end) ||  null;
    this.stripeProperties = new StripeProperties(data?.stripeProperties);
    this.price = data?.price;
    this.cancelStart = data?.cancelStart ? new Date(data?.cancelStart) : null
    this.cancelEnd = data?.cancelEnd ? new Date(data?.cancelEnd) : null
    this.isCanceled = data.isCanceled || false;
    this.quantity = data?.quantity;
    this.permanent = data?.permanent;
  }
}


export class Subscription {
  _id: string;
  subscriptionTemplate: SubscriptionTemplate;
  subscriptionProperties: SubscriptionProperties;
  contract: {
    yousignMemberId: string;
    yousignAuthId: string;
    status: string;
  };
  payment: Payment;
  athleteId: string;
  defaultPaymentMethod: {type: string};
  schedulePayments: {_id: number; date: Date; amount: number; type: string} [];
  stripeSession: {id: string; status: string; url: string};
  constructor(data: any) {
    data = data || {};
    this._id = data._id || null;
    this.subscriptionTemplate = data.subscriptionTemplate || null;
    this.subscriptionProperties = new SubscriptionProperties(data.subscriptionProperties) || null;
    this.contract = data.contract || null;
    this.payment = new Payment(data.payment);
    this.defaultPaymentMethod = data.defaultPaymentMethod || null;
    this.athleteId = data.athlteId || null;
    this.schedulePayments = data.schedulePayments || null;
    this.stripeSession = data.stripeSession || null;

  }
}
