import {LightAthlete} from '../../home/models/light-athlete';
import {Note} from './note';

export class CoachNotes {
  athletes: {
    athlete: LightAthlete;
    newMessages: boolean;
    notes: Note [];
  } [];
  notes: Note [];
}
