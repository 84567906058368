import {Inject, Injectable, InjectionToken} from '@angular/core';
import {AuthClientConfig, AuthService} from '@auth0/auth0-angular';
import {take} from 'rxjs/operators';
import {firstValueFrom, lastValueFrom, mergeMap, of} from 'rxjs';
import {StateService} from './state.service';
import {AthleteService} from './athlete/services/athlete.service';
import {WonderPush} from '@awesome-cordova-plugins/wonderpush/ngx';
import {returnTo} from '../auth.config';
import {Browser} from '@capacitor/browser';



@Injectable({providedIn: 'root'})
export class InitService {


  constructor(
    private auth: AuthService,
    private stateService: StateService,
    private athleteService: AthleteService,
    private wonderPush: WonderPush,
  ) {

  }

  init() {
    return new Promise<void>(async (resolve, reject) => {

      this.auth.isAuthenticated$.subscribe(async (isAuth) => {
        if(isAuth) {
          try {
            const orgs = await lastValueFrom(this.athleteService.getAthleteOrgs());
            const favouriteOrg = orgs?.find(org => org.isFavourite);
            this.stateService.currentTenant$.next(favouriteOrg);
            const athlete = await lastValueFrom(this.athleteService.getAthleteById());
            this.stateService.athlete$.next(athlete);
            this.wonderPush.setUserId(athlete.authzId).catch(err => console.log(err));
            setTimeout(()=>{}, 1000);
            await this.stateService.refreshPlans();
            await this.stateService.refreshNotifications();
            await this.stateService.refreshAthleteOrgs$();
            await this.stateService.refreshOrganizations();
            await this.stateService.refreshPrTemplatesGroupedByFamilies();
            await this.stateService.refreshFluxes();
            await this.stateService.refreshCoachNotes();
            await this.stateService.refreshAthleteUpComingActivities();
            await this.stateService.refreshAthleteSubscriptions();
            await this.stateService.refreshCurrentOrganizationPlan((await firstValueFrom(this.stateService.currentTenant$))._id);
            await this.stateService.refreshAthletePaymentMethodsStatus();
            await this.stateService.refreshAthleteOrders();
            await this.stateService.refreshAthleteInyourboxCredit();
            await this.stateService.refreshShopCategories();
            await this.stateService.refreshInyourboxGeneratorOder();
            await this.wonderPush.subscribeToNotifications();
            resolve();
          }catch (e) {
            console.log(e);
            resolve();
          }
        } else {
          resolve();
          await this.logout();
        }
      });
    });

  }


  logout() {
    // Use the SDK to build the logout URL
    this.auth
    .logout({
      logoutParams: {
        returnTo,
      },
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      async openUrl(url: string) {
        await Browser.open({ url });
      }
    })
    .subscribe(
      async () => {
      }
    );
  }


}
