import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Organization} from '../models/organization';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private _baseUrl = `${environment.api.url}/organizations`;
  constructor(private _http: HttpClient) { }

  getOrganizations() {
    return this._http.get<Organization []>(`${this._baseUrl}`);
  }


  getOrganizationById(id: string) {
    return this._http.get<Organization>(`${this._baseUrl}/${id}`);
  }

}
