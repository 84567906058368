export class Plan {
  _id: string;
  name: string;
  description: string;
  type: string;
  numberOfWeeks: number;
  image: any;
  price: number;
  billing: string;
  displayAll: boolean;
  isPersonalTraining: boolean;

  constructor(data?: any) {
    data = data || {};
    this._id = data._id;
    this.name = data.name;
    this.description = data.description;
    this.type = data.type;
    this.numberOfWeeks = data.numberOfWeeks;
    this.image = data.image;
    this.price = data.price;
    this.billing = data.billing;
    this.displayAll = data.displayAll;
    this.isPersonalTraining = data.isPersonalTraining;
  }
}
