import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Plan} from '../models/plan';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  private baseUrl =  `${environment.api.url}/plans`;

  constructor(private http: HttpClient) { }



  getPlans(): Observable<Plan []> {
    return this.http.get<Plan []>(`${this.baseUrl}/athlete`).pipe(
      map(plans => plans.map(p => new Plan(p))),
    );
  }


  getAllForShop(): Observable<Plan []> {
    return this.http.get<Plan []>(`${this.baseUrl}/shop`).pipe(
      map(plans => plans.map(p => new Plan(p))),
    );
  }

  buy(planId: string) {
    return this.http.get<{ url: string }>(`${this.baseUrl}/${planId}/buy`);
  }


}
