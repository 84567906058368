import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '@auth0/auth0-angular';
import {AuthGuard} from '@auth0/auth0-angular';
import {mergeMap} from 'rxjs/operators';
import WonderPush from 'wonderpush-cordova-sdk';

@Injectable({
  providedIn: 'root'
})
export class InYourBoxAuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean>  {
    return this.auth.isAuthenticated$.pipe(
      mergeMap((isAuthenticated) => {
        if(isAuthenticated) {
          return of(true);
        }
        this.router.navigate(['login']);
        return of(false);
      })
    );
  }

}
