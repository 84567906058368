import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MbscModule} from '@mobiscroll/angular';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, isDevMode} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {InyourboxInterceptorService} from './inyourbox-interceptor.service';
import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common';
import {AuthConfig, AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
registerLocaleData(localeFr);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
import  { domain, clientId, redirect_uri } from '../auth.config';
import {environment} from '../environments/environment';
import {WonderPush} from '@awesome-cordova-plugins/wonderpush/ngx';
import {StateService} from './state.service';
import {InitService} from './init.service';
import { ServiceWorkerModule } from '@angular/service-worker';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function initializeApp(initService: InitService) {
  return (): Promise<any> => initService.init();
}


const config: AuthConfig = {
  domain,
  clientId,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  useRefreshTokensFallback: false,
  authorizationParams:{
    // eslint-disable-next-line @typescript-eslint/naming-convention
    redirect_uri,
    audience: environment.authz.audience
  },
  httpInterceptor: {
    allowedList: [
      {
        uri: `${environment.api.url}*`,
        uriMatcher: (value) => !(value.indexOf('assets') > -1 || (value.indexOf('register') > -1 && value.indexOf('add-org') === -1)),
        tokenOptions: {
          authorizationParams: {
            audience: environment.authz.audience
          }
        }
      }
    ]
  }

};

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    AppComponent,
  ],
  imports: [
    FormsModule,
    MbscModule,
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
      animated: false
    }),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AuthModule.forRoot(config),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ InitService ],
      multi: true,
    },
    {provide: HTTP_INTERCEPTORS, useClass: InyourboxInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    WonderPush,
    StateService
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.setDefaultLang('fr');
    translate.use('fr');
  }
}
