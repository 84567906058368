export class Payment {
  type: string;
  date: number;
  constructor(data?: any) {
    data = data || {};
    this.type = data.type;
    this.date = data.date;
  }

}
